import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./IconsBar.css"

const Icons = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "home"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                textWithIcon1 {
                  icon {
                    publicURL
                  }
                  text
                }
                textWithIcon2 {
                  icon {
                    publicURL
                  }
                  text
                }
                textWithIcon3 {
                  icon {
                    publicURL
                  }
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const Icon = ({ text, Image }) => (
    <div className="Icon">
      <div className="Image">
        <img src={Image} alt="Decorative image." />
      </div>
      <p>{text}</p>
    </div>
  )

  return (
    <div className="IconsBar">
      <Icon text={data.textWithIcon1.text} Image={data.textWithIcon1.icon.publicURL} />
      <Icon text={data.textWithIcon2.text} Image={data.textWithIcon2.icon.publicURL} />
      <Icon text={data.textWithIcon3.text} Image={data.textWithIcon3.icon.publicURL} />
    </div>
  )
}

export default Icons
