import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeroBox from "../components/HeroBox"
import LayoutInner from "../components/LayoutInner"
import Description from "../components/Description"
import ButtonAction from "../components/ButtonAction"
import IconsBar from "../components/IconsBar"
import FullWidthBar from "../components/FullWidthBar"
import WideImage from "../components/WideImage"
import CarouselHome from "../components/CarouselHome"
import ContactBar from "../components/ContactBar"

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "home"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              headerTitle
              headerSubtitle
              paragraph1Heading
              paragraph1Text
              contactButtonText
              paragraph2Heading
              paragraph2Text
              paragraph2Image {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = ( props ) => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <>
      <Layout>
        <Seo title="Home" />
        <HeroBox heading={data.headerTitle} text={data.headerSubtitle} />
        <LayoutInner>
          <Description heading={data.paragraph1Heading} text={data.paragraph1Text} />
          <ButtonAction
            text={data.contactButtonText}
            page="contact"
            darkButton={true}
          />
          <IconsBar />
          <FullWidthBar>
            <Description heading={data.paragraph2Heading} text={data.paragraph2Text} smallHeading={true} />
            <WideImage image={data.paragraph2Image.childImageSharp.fluid} />
          </FullWidthBar>
          <CarouselHome />
        </LayoutInner>
        <ContactBar />
      </Layout>
    </>
  )
}

export default IndexPage
