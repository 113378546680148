import React from "react"
import Img from "gatsby-image"

import "./WideImage.css"

const WideImage = ({ image }) => {
  return (
    <div className="WideImageContainer">
      <Img fluid={image} />
    </div>
  )
}

export default WideImage
