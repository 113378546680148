import React from "react"
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby"

import "./Carousel.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselHome = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "carouselHome"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                carouselHeading
                testimonials {
                  text
                  author
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  var carouselSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const Bubble = ({ quote, author }) => {
    return (
      <div className="thoughtContainer">
        <div className="thought">
          <div className="thoughtInner">
            <p>{quote}</p>
            <p className="quoteAuthor">- {author}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="Carousel">
      <h3>{data.carouselHeading}</h3>
      <div className="CarouselContainer">
        <Slider {...carouselSettings}>
          {data.testimonials.map(I => (
            <div className="CarouselItemContainer">
              <Bubble
                quote={I.text}
                author={I.author}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default CarouselHome
